import { TourStartPage } from '@app/pages/tour-start-page/TourStartPage';
import { atom_tourState } from '@app/shared/state/tour.atom';
import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OpeningStopBlock } from '@app/features/map/opening-stop-block/OpeningStopBlock';
import { motion, AnimatePresence, HTMLMotionProps } from 'framer-motion';
import { atom_openedStopState } from '@app/shared/state/opened-stop.atom';
import { atom_hideState, atom_loadingState, atom_startTourState } from '@app/shared/state/loading.atom';
import { BigStopPage } from '@app/features/map/big-stop-page/BigStopPage';
import { atom_activeStopState } from '@app/shared/state/active-stop.atom';
import { useTranslation } from 'react-i18next';
import crypto from 'crypto-js';
import { deleteAllCachesWithRetry, deleteAllDatabases } from '@app/pages/delete-dbs';

type TourSummaryOverlayProps = {
	children?: React.ReactNode;
};

const framerSlideLeft: HTMLMotionProps<'div'> = {
	transition: { duration: 0.3, ease: 'easeInOut' },
	initial: { translateX: '100vw' },
	animate: { translateX: '0vw' },
	exit: { translateX: '-100vw' }
};

const framerSlideUpDown: HTMLMotionProps<'div'> = {
	transition: { duration: 0.2, ease: 'easeInOut' },
	initial: { translateY: '100vh' },
	animate: { translateY: '0vh' },
	exit: { translateY: '100vh' }
};

const wait = async (time: number) => {
	return new Promise((res: any, rej) => {
		setTimeout(() => {
			res();
		}, time);
	});
};

export const TourSummaryOverlay: React.FC = React.memo<TourSummaryOverlayProps>(
	function TourSummaryOverlay(_p) {
		const { t } = useTranslation();
		let state_tourState = useRecoilValue(atom_tourState) as any;
		const [state_loadingState, state_setLoadingState] = useRecoilState(
			atom_loadingState
		);
		const [hidePage, setHidePage] = useRecoilState(atom_hideState)
		const [startTour, setStartTour] = useRecoilState(atom_startTourState);
		const [state_openedStopState, setState_openedStopState] = useRecoilState(
			atom_openedStopState
		);
		const [showReloadButton, setShowReloadButton] = useState(false);
		const queryParams = new URLSearchParams(location.search);
		const uID = queryParams.get('uID');
		const gID = queryParams.get('gID');
		const taID = queryParams.get('taID') as string;
		if (!!Number(taID) && state_tourState?.id) {
			localStorage.setItem('tasteCount_' + state_tourState?.id, taID);
		}

		useEffect(() => {
			console.log('gID', gID, 'uID', uID, 'tID', state_tourState?.id);
			if (gID && uID && state_tourState?.id) {
				localStorage.setItem('credential_items', JSON.stringify({ gID, uID, tID: state_tourState?.id }));
			}

		}, [gID, uID, state_tourState?.id]);



		const [isAllowed, setIsAllowed] = useState(true);
		const [_x, setState_activeStopState] = useRecoilState(
			atom_activeStopState
		);

		const [downloading, setDownloading] = useState(false);
		useEffect(() => {
			setTimeout(() => {
				state_tourState = true;
			}, 3000);

			setTimeout(() => {
				setShowReloadButton(true);
			}, 5500);

		}, []);

		useEffect(() => {
			const cachedStop = Object.values(JSON.parse(localStorage.getItem('visitedStops') || '{}'));
			const latestStop = state_tourState?.stops.find((stop: any) => stop.id === cachedStop[cachedStop.length - 1]);
			setState_activeStopState(latestStop || state_tourState?.stops[0]);
		}, [state_tourState?.stops[0]]);

		const handleIsAllowed = async () => {
			if (localStorage.getItem('allowed_' + state_tourState?.id)) {
				setIsAllowed(true)
				return;
			}

			const fingerprint = {
				// userAgent: window.navigator.userAgent,
				width: window.screen.width,
				height: window.screen.height,
				language: window.navigator.language,
				platform: window.navigator.platform,
				hardwareConcurrency: window.navigator.hardwareConcurrency
			};
			const uniqueID = crypto
				.MD5(JSON.stringify(fingerprint))
				.toString(crypto.enc.Base64);
			const allowed = await fetch(`https://europe-west3-tours-app-1579553856346.cloudfunctions.net/validateWebUsage1?tID=${state_tourState?.id}&gID=${gID}&uID=${uID}&uniqueID=${uniqueID}`)
				.then(res => res.json())
			if (allowed) {
				setIsAllowed(true)
				localStorage.setItem('allowed_' + state_tourState?.id, 'true')
			}
		}

		useEffect(() => {
			if (state_tourState?.id) {
				handleIsAllowed()
			}
		}, [state_tourState]);

		return (
			<AnimatePresence>
				{(!state_tourState || state_loadingState) && (
					<Box
						className="fixed top-0 bottom-0 w-screen h-screen bg-white overflow-auto flex flex-col justify-center items-center"
						sx={{ zIndex: 99999 }}
					>
						<Typography variant="h2" className="mb-2">
							{t('pre_start_tour.loading_tour.tour_is_on_its_way_to_you')}
						</Typography>
						<CircularProgress />
						{showReloadButton && (
							<Button

								onClick={async () => {
									await deleteAllDatabases()
									await deleteAllCachesWithRetry();
									window.location.reload();
								}
								}
							>
								{t('pre_start_tour.reload_tour')}
							</Button>
						)}
					</Box>
				)}
				{state_tourState && !hidePage && (
					<motion.div
						className="fixed top-0 bottom-0 w-screen h-screen bg-white overflow-auto"
						style={{ zIndex: 999, opacity: downloading ? 0 : 1 }}
						transition={{ ...framerSlideLeft, initial: { translateX: '0vw' } }}
					>
						{isAllowed ? (
							<TourStartPage
								onClickStart={() => {
									setHidePage(true);
									setStartTour(true);
								}}
								tour={state_tourState}
								className=""
							/>
						) : (
							<Box className="flex justify-center ">
								<Typography
									style={{
										height: '80vh',
										display: 'flex',
										alignItems: 'center'
									}}
								>
									YOU ARE NOT ALLOWED TO ACCESS THIS PAGE
								</Typography>
							</Box>
						)}
					</motion.div>
				)}
				{state_tourState && startTour && (
					<motion.div
						className="fixed top-0 bottom-0 w-screen h-screen bg-white overflow-auto"
						style={{ zIndex: 1000, height: 1000 }}
						{...framerSlideLeft}
					>
						<OpeningStopBlock
							stop={state_tourState.stops[0]}
							onClickSkip={() => setStartTour(false)}
						/>
					</motion.div>
				)}
				{state_tourState && state_openedStopState && (
					<motion.div
						key={'motion-key'}
						className="fixed top-0 bottom-0 w-screen h-screen bg-white overflow-auto"
						style={{ zIndex: 1000 }}
						{...framerSlideUpDown}
					>
						<BigStopPage
							stop={state_openedStopState}
							onClickBack={() => setState_openedStopState(undefined)}
						/>
					</motion.div>
				)}

			</AnimatePresence>
		);
	}
);
