import React, { useEffect, useState } from 'react';
import {
	Box,
	BoxProps,
	Typography,
	Link,
	Button,
	Divider,
} from '@mui/material';
import { Seperator, TourChip, UserBlock } from '@app/shared/components';
import ReactPlayer from 'react-player';
import { Tour } from '@app/shared/models/tour.type';
import { NavigationOptions } from '@app/shared/components/NavigationOptions';
import { Modal } from 'react-responsive-modal';
import { version } from 'package.json';

import { LinearWithValueLabel } from './Progress';
import { useTranslation } from 'react-i18next';
import { atom_tourState } from '@app/shared/state/tour.atom';
import { useRecoilState, useRecoilValue } from 'recoil';
// import { DeviceUUID } from "device-uuid";
import crypto from 'crypto-js';
import { atom_downloadProgressBar } from '@app/shared/state/show-content-modal.atom';
import { atom_DownloadModeState } from '@app/shared/state/download-mode';
import { atom_activeStopState } from '@app/shared/state/active-stop.atom';
import { TourDataFetcher } from '@app/features/tour-data-fetcher/tour-data-fetcher';
import startIllu from '@app/assets/images/start.png';
import midIllu from '@app/assets/images/mid.png';
import endIllu from '@app/assets/images/end.png';
import { deleteAllCachesWithRetry, deleteAllDatabases } from 'src/pages/delete-dbs';

type TourStartPageProps = {
	tour: Tour;
	onClickStart?: () => void;
	children?: React.ReactNode;
} & BoxProps;

const wait = async (time: number) => {
	return new Promise((res: any, rej) => {
		setTimeout(() => {
			res();
		}, time);
	});
};
export const TourStartPage = React.forwardRef<BoxProps, TourStartPageProps>(
	function TourStartPage(_p, ref) {
		const fetchTourOffline = TourDataFetcher();

		const [isLoading, setIsLoading] = useState(false);
		const [isShowModal, setIsShowModal] = useState(false);
		const [isFinished, setIsFinished] = useState(false);
		const { i18n, t } = useTranslation();
		const state_tourState = useRecoilValue(atom_tourState);
		const [uniqueId, setUniqueId] = useState('');

		const [state_progressBar, state_setProgressBar] = useRecoilState(
			atom_downloadProgressBar
		);
		const [_x, setState_activeStopState] = useRecoilState(
			atom_activeStopState
		);
		const [_y, setState_isDownloadMode] = useRecoilState(atom_DownloadModeState)

		const [downloading, setDownloading] = useState(false);

		// let uniqueDeviceId = "";

		useEffect(() => {
			const fingerprint = {
				// userAgent: window.navigator.userAgent,
				width: window.screen.width,
				height: window.screen.height,
				language: window.navigator.language,
				platform: window.navigator.platform,
				hardwareConcurrency: window.navigator.hardwareConcurrency
			};
			const uniqueID = crypto
				.MD5(JSON.stringify(fingerprint))
				.toString(crypto.enc.Base64);
			setUniqueId(uniqueID);
			(i18n as any).changeLanguage(
				state_tourState?.language === 'Hebrew' ? 'he' : 'en'
			);
			document.dir = state_tourState?.language === 'Hebrew' ? 'rtl' : 'ltr';
		}, [state_tourState]);

		const renderDownloadStep1 = () => {
			return <div>
				<Typography >{t('pre_start_tour.on_download_modal.title_v2')}</Typography>
				<Divider />
				<Box className="flex items-center m-5">
					<Typography style={{ wordBreak: 'break-word' }} variant='body2'>{t('pre_start_tour.on_download_modal.description_v2')}</Typography>
				</Box>
				<Box className="flex items-center m-5 justify-center">
					<img src={startIllu} />
				</Box>
				<Box
					className="flex items-center m-5 justify-center">
					<Button
						onClick={async () => {
							try {

								await deleteAllDatabases()
							} catch (er) {
								console.log('db delete error: ', er)
							}
							try {

								await deleteAllCachesWithRetry();
							} catch (er) {
								console.log('cache delete error: ', er)
							}
							console.log('deleted!')
							localStorage.setItem('downloaded_done' + state_tourState?.id, 'step1');
							window.location.reload();
						}}
					>{
							t('pre_start_tour.on_download_modal.button_v2')
						}</Button>

				</Box>


			</div>
		}

		const renderDownloadStep3 = () => {
			return <div>
				<Typography >{t('pre_start_tour.on_download_modal.title_v2')}</Typography>
				<Divider />
				<Box className="flex items-center m-5">
					<Typography style={{ wordBreak: 'break-word' }} variant='body2'>{t('pre_start_tour.on_download_modal.description_v2_step3')}</Typography>
				</Box>

				<Box className="flex items-center m-5 justify-center"
				>
					<img src={midIllu} />
				</Box>
				<Box className="">
					<LinearWithValueLabel isFinished={isFinished} />
				</Box>
				<Box
					className="flex items-center m-5 justify-center">

					<Button
						onClick={async () => {
							localStorage.setItem('downloaded_done' + state_tourState?.id, 'done');
						}}
					>{
							t('pre_start_tour.on_download_modal.cancel')
						}</Button>
				</Box>

			</div>

		}

		const renderDownloadStep4 = () => {
			return <div>
				<Typography >{t('pre_start_tour.on_download_modal.title_v2')}</Typography>
				<Divider />
				<Box className="flex items-center m-5">
					<Typography style={{ wordBreak: 'break-word' }} variant='body2'>{t('pre_start_tour.on_download_modal.description_v2_step4')}</Typography>
				</Box>
				<Box className="flex items-center m-5 justify-center"
				>
					<img src={endIllu} />
				</Box>
				<Box
					className="flex items-center m-5 justify-center">

					<Button
						onClick={async () => {
							localStorage.setItem('downloaded_done' + state_tourState?.id, 'done');
							window.location.reload();
						}}
					>{
							t('pre_start_tour.on_download_modal.close')
						}</Button>
				</Box>
			</div>
		}

		const renderDownloadStep2 = () => {
			return <div>
				<Typography >{t('pre_start_tour.on_download_modal.step2_title_v2')}</Typography>
				<Divider />
				<Box className="flex items-center m-5">
					<Typography style={{ wordBreak: 'break-word' }} variant='body1'>{t('pre_start_tour.on_download_modal.step2_description_v2')}</Typography>
				</Box>
				<Box className="flex items-center m-5 justify-center"
				>
					<img src={midIllu} />
				</Box>
				<Box
					className="flex items-center m-5 justify-center">
					{
						<Button
							onClick={async () => {
								try {
									setDownloading(true);
									await fetchTourOffline(state_tourState as any);
									setState_isDownloadMode(true);

									const numOfStops = state_tourState?.stops?.length || 0;
									const toBump = 5 / numOfStops;
									for (let i = 0; i < numOfStops || 0; i++) {
										setState_activeStopState(state_tourState?.stops[i]);
										await wait(3000);
										const number = 95 + Math.floor(toBump * i + 1)

										state_setProgressBar(number > 100 ? 98 : number);
										console.log('waited for ', i);
									}
									setState_activeStopState(state_tourState?.stops[0]);
									await wait(2000);

									setIsFinished(true);

									// state_setLoadingState(false);
								} catch (err) {
									console.log({ err });
									alert('download issue');
								} finally {
									setState_isDownloadMode(false);
									setDownloading(false);
								}
							}}
						>{
								t('pre_start_tour.on_download_modal.step2_button_v2')
							}</Button>}
				</Box>


			</div>
		}



		const renderCurrentStep = () => {
			if (isFinished) return renderDownloadStep4();
			if (downloading) return renderDownloadStep3();
			return localStorage.getItem('downloaded_done' + state_tourState?.id) === 'step1' ? renderDownloadStep2() :
				localStorage.getItem('downloaded_done' + state_tourState?.id) === 'done' ?
					<div></div> : renderDownloadStep1()
		}


		return (
			<Box ref={ref}>
				<Box className="flex items-center justify-center bg-white ">
					{_p.tour?.starting_video && (
						<ReactPlayer
							style={{ maxHeight: '40vh' }}
							width="100%"
							url={_p.tour.starting_video.split('&token=')[0]}
							controls
						/>
					)}
					{_p.tour?.starting_image && (
						<img

							style={{ maxHeight: '40vh', width: '100%' }}
							src={_p.tour?.starting_image.split('&token=')[0]}
						/>
					)}
				</Box>
				<Box className="p-4">
					<Typography variant="h1">{_p.tour?.title}</Typography>
					<Seperator />

					<UserBlock
						title={t('pre_start_tour.your_guide')}
						name={_p.tour?.tour_guide?.full_name}
						image_url={_p.tour?.tour_guide?.profile_image.split('&token')?.[0]}
					/>
					<Seperator />
					{_p?.tour?.equipment?.length > 0 && (
						<>

							<Typography variant="body2" className="mb-2" fontWeight={800}>
								{t('pre_start_tour.important_to_bring')}
							</Typography>
							<Box className="flex flex-wrap gap-4 mb-4">
								{_p?.tour?.equipment?.map(item => (
									<TourChip
										key={'equipment' + item}
										icon_type="solid"
										icon_name={iconsMap[item]}
										fontSize="0.8em"
									>
										{item}
									</TourChip>
								))}
							</Box>
						</>
					)
					}
					{(_p?.tour?.equipment?.length > 0 || _p?.tour?.distance || _p?.tour?.duration || _p?.tour?.parking || Array.isArray(_p.tour?.notes)) &&
						<>
							<Typography variant="body2" className="mb-2" fontWeight={800}>
								{t('pre_start_tour.tour_details')}
							</Typography>
							<Box className="flex flex-wrap gap-4">
								{_p.tour?.distance &&
									<TourChip
										icon_type="solid"
										icon_name="map-location"
										fontSize="0.8em"
									>
										{(_p.tour?.distance / 1000).toFixed(1)} km
									</TourChip>}
								{_p.tour?.duration &&
									<TourChip icon_type="solid" icon_name="clock">
										{_p.tour?.duration}
									</TourChip>}
							</Box>
							{_p?.tour?.parking && (
								<div>
									<Seperator />

									<Box className="flex justify-between">
										<TourChip icon_type="solid" icon_name="p" fontSize="0.95em">
											{/* <span>חניה: &nbsp;</span> */}
											<Link>{_p?.tour?.parking?.name}</Link>
										</TourChip>
										<Box className="flex gap-3 items-center">
											<NavigationOptions
												stop={{ location: _p?.tour?.parking } as any}
											/>
										</Box>
									</Box>
								</div>
							)}
							<Seperator />
							{Array.isArray(_p.tour?.notes) ? (
								_p.tour?.notes.map((txt, i) => (
									<div key={'noteskey' + i} className="fragment">
										<Typography key={'noteskeytypog' + i} variant="body1" className="mb-1">
											{txt.question}
										</Typography>
										<Typography key={i + 'xnotes'} variant="body2" style={{ wordBreak: 'break-word' }} className="mb-1">
											{txt.answer}
										</Typography>
									</div>
								))
							) : (
								<Typography variant="body2" className="mb-4"></Typography>
							)}
						</>
					}
					<Typography variant="body2" className="mb-4"></Typography>

					<Box className="flex flex-col">
						<Button className="mb-5" onClick={_p.onClickStart}>
							{t('pre_start_tour.button_get_started')}
						</Button>

						<Typography
							variant="body2"
							className="mb-2 text-center"
							fontWeight={800}
							style={{ wordBreak: 'break-word' }}
						>
							{t('pre_start_tour.download_description')}
						</Typography>
						<Button
							className="mb-10"
							color="secondary"
							onClick={() => {
								localStorage.removeItem('downloaded_done' + state_tourState?.id);

								setIsShowModal(true)
							}}
						>
							{t('pre_start_tour.pre_download_modal.download')}
						</Button>
					</Box>
					<Seperator className="mb-10 opacity-0" />
					<Box className="flex justify-center">
						{'v' + version}
					</Box>
				</Box>
				{/* {getOldDownloadScreen()} */}
				<Box sx={{
					zIndex: 999
				}} className="wtf">
					{
						((localStorage.getItem('downloaded_done' + state_tourState?.id) && localStorage.getItem('downloaded_done' + state_tourState?.id) !== 'done') || isShowModal) &&
						<Modal styles={{
							modal: {
								zIndex: 999
							},
							modalContainer: {
								zIndex: 999
							}
						}} open={true} onClose={() => {
							localStorage.setItem('downloaded_done' + state_tourState?.id, 'done');
							window.location.reload();
						}} center>
							{renderCurrentStep()}
						</Modal>}
				</Box>
			</Box>
		);
	}
);

const iconsMap: any = {
	מים: 'bottle-water mt-0.5',
	'סוללה מלאה': 'battery-bolt fa-rotate-270',
	'נעלי הליכה נוחות': 'shoe-prints',
	אוזניות: 'headphones'
};
