// eslint-disable-next-line @typescript-eslint/ban-ts-comment
export async function deleteAllDatabases() {
	try {
		const dbs = await (window.indexedDB).databases() as { name: string }[];
		console.log('Databases:', JSON.stringify(dbs));

		for (const db of dbs) {
			await closeConnections(db.name);
			await deleteDatabase(db.name);
		}
	} catch (error) {
		console.error('Error deleting databases:', error);
	}
}

function closeConnections(dbName: string) {
	return new Promise<void>((resolve, reject) => {
		const request = window.indexedDB.open(dbName);

		request.onsuccess = function (event) {
			const db = (event as any).target.result;
			db.close();
			resolve();
		};

		request.onerror = function (event) {
			console.error(`Failed to open connection to ${dbName}`, event);
			reject(event);
		};
	});
}

function deleteDatabase(dbName: string) {
	return new Promise<void>((resolve, reject) => {
		const request = window.indexedDB.deleteDatabase(dbName);

		request.onsuccess = function () {
			console.log(`Deleted database ${dbName} successfully`);
			resolve();
		};

		request.onerror = function (event) {
			console.error(`Couldn't delete database ${dbName}`, event);
			reject(event);
		};

		request.onblocked = function () {
			console.log(`Couldn't delete database ${dbName} due to the operation being blocked`);
			// Optionally, you can add more logic to handle this case
			// such as retrying after some delay
			reject(new Error(`Blocked deletion of ${dbName}`));
		};
	});
}



export async function deleteAllCachesWithRetry(retryDelay = 1000, maxRetries = 3) {
	try {
		const cacheNames = await caches.keys();

		for (const cacheName of cacheNames) {
			await deleteCacheWithRetry(cacheName, retryDelay, maxRetries);
		}
	} catch (error) {
		console.error('Error deleting caches:', error);
	}
}

function deleteCacheWithRetry(cacheName: string, retryDelay = 1000, maxRetries = 3) {
	return new Promise<void>((resolve, reject) => {
		let attempts = 0;

		async function attemptDelete() {
			try {
				const deleted = await caches.delete(cacheName);

				if (deleted) {
					console.log(`Deleted cache ${cacheName} successfully`);
					resolve();

				} else {
					console.warn(`Couldn't delete cache ${cacheName}. Retrying...`);
					retryDelete();
				}

			} catch (error) {
				console.error(`Error deleting cache ${cacheName}`, error);
				retryDelete();
			}
		}

		function retryDelete() {
			if (attempts < maxRetries) {
				attempts++;
				setTimeout(attemptDelete, retryDelay);
			} else {
				reject(new Error(`Failed to delete cache ${cacheName} after ${maxRetries} attempts`));
			}
		}

		attemptDelete();
	});
}
