import React from "react";
import { Tour } from "@app/shared/models/tour.type";
import { useRecoilState, useSetRecoilState } from "recoil";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import openrouteservice from "openrouteservice-js";
import { atom_downloadProgressBar } from "@app/shared/state/show-content-modal.atom";
import { useTranslation } from "react-i18next";

export const TourDataFetcher = () => {
  const [state_progressBar, setState_progressBar] = useRecoilState(
    atom_downloadProgressBar
  );
  const { t } = useTranslation();
  return async (tour: Tour) => {
    const items = await fetch(
      `https://europe-west6-tours-app-1579553856346.cloudfunctions.net/getAllAss/?tourID=${tour.id}`
    ).then(response => response.json());
    let done = 0;
    let numOfErrors = 0;
    const eachBump = 100 / items.length;
    for (let i = 0; i < items.length; i++) {
      await fetch(items[i]?.split("&token=")?.[0])
        .then(response => response.blob())
        .catch(err => numOfErrors++);
      if (numOfErrors >= 3) {
        alert(t("pre_start_tour.on_download_modal.download_error"));
        window.location.reload();
      }
      done += Math.round(eachBump);
      setState_progressBar(done);
    }
  };
};
