import { Box } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import useGeolocation from 'react-hook-geolocation';
import { Pane } from 'react-leaflet';
import { Marker, MarkerLayer } from 'react-leaflet-marker';

type DeviceLocationLayerProps = {
	children?: React.ReactNode;
};

export function DeviceLocationLayer(_p: DeviceLocationLayerProps) {
	const geolocation = useGeolocation({
		enableHighAccuracy: true,
		maximumAge: 15000,
		timeout: 60000,
	});

	const getHeading = useCallback(() => {
		return geolocation.heading || 0;
	}, [geolocation?.heading]);

	if (!geolocation?.latitude || !geolocation?.longitude) {
		return null;
	}


	return (
		<div className="fragment">
			<Pane name="device-location" style={{ zIndex: 999999 }}>
				<MarkerLayer>
					<Marker
						key="device-location-1"
						position={{
							lat: geolocation.latitude,
							lng: geolocation.longitude,
						}}
						placement="bottom"
						size={[0, 0]}
					>
						{/* <div>{geolocation?.heading}</div> */}
						{/* <Box className="w-5 h-5 bg-blue-500 rounded-full"> */}
						<Box id="wrapapp">
							<div
								style={{
									transform: `rotate(${getHeading().toFixed(0)}deg)`,
								}}
								className="triangle"
							></div>
						</Box>
					</Marker>
				</MarkerLayer>
			</Pane>
		</div>
	);
}