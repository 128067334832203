import React, { useEffect } from 'react';
import {
	createBrowserRouter,
	redirect,
	RouterProvider
} from 'react-router-dom';
import { RootLayout } from '@app/core/layouts/RootLayout';
import { ElementsPage } from '@app/pages/elements-page/ElementsPage';
import { TourStartPage } from '@app/pages/tour-start-page/TourStartPage';
import { MapPage } from '@app/pages/map-page/MapPage';
import { Button, Typography } from '@mui/material';
type RoutingLayerProps = {
	children?: React.ReactNode;
};


const router = createBrowserRouter([
	{
		path: '/',
		element: <RootLayout />,
		children: [
			{
				id: 'map-router',
				path: 'tour/:tourId',
				element: <MapPage />
			},
			{
				id: 'elements-router',
				path: 'elements',
				element: <ElementsPage />
			}
		]
	}
]);

export function RoutingLayer() {
	// alert(JSON.stringify({ rendered }));
	// if (rendered) {
	// 	window.location.reload();
	// }
	// useEffect(() => {
	// 	window.onerror = function (message, source, line, column, error) {
	// 		window.onerror = null;
	// 		console.log({ message, source, line, column, error });
	// 		window.alert(JSON.stringify(message));

	// 		// window.location.reload();
	// 	};
	// }, []);

	return <RouterProvider router={router} />;
}


// export const RoutingLayer: React.FC<RoutingLayerProps> = function RoutingLayer() {
// 	alert(JSON.stringify({ rendered }));
// 	// if (rendered) {
// 	// 	window.location.reload();
// 	// }
// 	useEffect(() => {
// 		window.onerror = function (message, source, line, column, error) {
// 			window.onerror = null;
// 			console.log({ message, source, line, column, error });
// 			window.alert(JSON.stringify(message));

// 			// window.location.reload();
// 		};
// 	}, []);

// 	setTimeout(() => {
// 		rendered = true;
// 	}, 1000);
// 	return !rendered ? <RouterProvider router={router} /> : <RouterProvider router={router} />;
// };
