import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function ClaimStatus({ claimData, updateExpiration }: {
    claimData: {
        claimedAt: string;
    };
    updateExpiration: (status: boolean) => void;
}) {
	const { t } = useTranslation();
	const [timeLeft, setTimeLeft] = useState('');

	useEffect(() => {
		const getTimeLeft = () => {
			if (!claimData?.claimedAt) {
				return 'Invalid claim data';
			}
			// Parse the claimedAt time explicitly
			const claimedAtTime = moment(new Date(claimData.claimedAt), 'DD/MM/YY HH:mm')
			// Check if the claimedAt time was parsed correctly
			if (!claimedAtTime.isValid()) {
				return 'Invalid claim time';
			}

			// Get the current time
			const currentTime = moment()

			// Calculate the difference in milliseconds
			const timeDifference = currentTime.diff(claimedAtTime);

			// Convert 12 minutes to milliseconds
			const twelveMinutesInMilliseconds = 12 * 60 * 1000;

			// If 12 minutes or more have passed, return "expired"
			if (timeDifference >= twelveMinutesInMilliseconds) {
				return t('on_tour.stop.expired_description');
			}

			// Calculate the remaining time in milliseconds
			const remainingMilliseconds = twelveMinutesInMilliseconds - timeDifference;

			// Convert remaining time to minutes and seconds
			const minutesLeft = Math.floor(remainingMilliseconds / (60 * 1000));
			const secondsLeft = Math.floor((remainingMilliseconds % (60 * 1000)) / 1000);

			// Format the remaining time as "MM:SS"
			const formattedTimeLeft = `${minutesLeft.toString().padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`;

			return formattedTimeLeft;
		};
		const intervalId = setInterval(() => {
			const timeLeft = getTimeLeft();
			if (timeLeft === t('on_tour.stop.expired_description')) {
				setTimeLeft('expired');
				updateExpiration?.(true)
				return;
			}
			setTimeLeft(timeLeft);

		}, 1000); // Check every second

		// Cleanup interval on component unmount
		return () => clearInterval(intervalId);
	}, [claimData]);

	return (
		<Box display={'flex'} justifyContent={'center'}>
			<Typography className="flex justify-center" variant='h1'>{timeLeft}</Typography>
		</Box>
	);
}

export default ClaimStatus;